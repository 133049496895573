import styled from "styled-components"

import { spacing } from "src/ui/spacing"

import AndroidAppStoreImg from "./images/android-app-store.png"
import AppleAppStoreImg from "./images/apple-app-store.png"

export function AppStoreLogos({
  onAppStoreClick,
  onGooglePlayClick,
}: {
  onAppStoreClick?: () => void
  onGooglePlayClick?: () => void
}) {
  return (
    <Box>
      <a
        href="https://play.google.com/store/apps/details?id=com.minut.point"
        rel="noopener noreferrer"
        target="_blank"
        onClick={onGooglePlayClick}
      >
        <Icon src={AndroidAppStoreImg} alt="Android app store" />
      </a>
      <a
        href="https://apps.apple.com/se/app/point-soft-home-security/id1016451795"
        rel="noopener noreferrer"
        target="_blank"
        onClick={onAppStoreClick}
      >
        <Icon src={AppleAppStoreImg} alt="iOS app store" />
      </a>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  gap: ${spacing.S};
`

const Icon = styled.img`
  max-width: 120px;
`
